import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import PageBanner from "../components/elements/PageBanner";
import Layout from "../components/common/Layout";
import Seo from "../components/meta/Seo";
import { constructActiveDoc } from "../utilities/helpers";

import components from "../components/slices/index";

import "../styles/pages/simple_page.scss";

const SimplePage = ({ data }) => {
  if (!data) return null;

  const { page } = data;
  const { content } = page.data;

  return (
    <>
      <Seo page={page} />
      <Layout activeDocMeta={constructActiveDoc(page)}>
        <PageBanner
          title={page.data.title}
          bgImage={page.data.background_image}
          bgImageMobile={page.data.image_mobile}
          page={page}
        />
        <Container className="wrapper-simple max-container mona">
          <PrismicRichText field={content.richText} />
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query simplePageQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicSimplePage(lang: { eq: $lang }, id: { eq: $id }) {
      ...prismicSimplePageFragment
    }
  }
`;

export default SimplePage;
